'use client';
import { useState } from 'react';
import s9 from 'style9';

const c = s9.create({
  btnWrapper: {
    borderTop: '1px dashed rgb(var(--accent2),0.2)',
    display: 'flex',
    justifyContent: 'flex-end',
    gridColumnStart: 1,
    gridColumnEnd: -1,
    paddingTop: 'calc(var(--s) * 2 - 1px)',
    position: 'relative',

    '@media screen and (min-width: 64em)': {
      gridColumnEnd: 2,
    },
  },
  btnWrapperClosed: {
    '::after': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 'calc(var(--s) * -1)',
      transform: 'translateY(-100%)',
      width: '100%',
      height: '450%',
      backgroundImage: 'linear-gradient(rgba(var(--bg),0), rgb(var(--bg)))',
    },
  },
  btn: {
    borderRadius: 'var(--s)',
    color: 'rgb(var(--accent2))',
    cursor: 'pointer',
    fontFamily: 'var(--font-mono)',
    fontWeight: 700,
    paddingTop: 'calc(var(--s) * 1)',
    paddingBottom: 'calc(var(--s) * 1)',
    paddingInlineEnd: 'calc(var(--s) * 3)',
    paddingInlineStart: 'calc(var(--s) * 3)',
    transition: 'all 0.25s ease-in-out',
    outlineColor: 'transparent',
    scale: 1,

    ':hover': {
      color: 'rgb(var(--accent1))',
      backgroundColor: 'rgb(var(--accent1),0.1)',
    },
    ':focus': {
      outlineStyle: 'dashed',
      outlineColor: 'rgb(var(--accent1),0.3)',
      outlineWidth: '1px',
      color: 'rgb(var(--accent1))',
      backgroundColor: 'rgb(var(--accent1),0.1)',
    },
    ':active': {
      scale: 0.95,
    },
  },
});

interface ReadMoreProps {
  children: React.ReactNode;
  showMoreText?: string;
  showLessText?: string;
}

export default function ReadMore({
  children,
  showMoreText = 'Read More',
  showLessText = 'Show Less',
}: ReadMoreProps) {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <>
      {isExpanded ? children : null}
      <footer className={s9(c.btnWrapper, !isExpanded && c.btnWrapperClosed)}>
        <button onClick={() => setIsExpanded(!isExpanded)} className={s9(c.btn)}>
          {isExpanded ? showLessText : showMoreText}
        </button>
      </footer>
    </>
  );
}
