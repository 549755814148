'use client';

import { useState } from 'react';
import s9 from 'style9';

import Icon from './Icon';

import type { IconName } from './Icon';

const c = s9.create({
  preCopyButton: {
    alignItems: 'center',
    color: 'rgb(var(--fg-dim0))',
    display: 'flex',
    cursor: 'pointer',
    fontSize: 'var(--fz--3)',
    // lineHeight: 'var(--lh--3)',
    lineHeight: 'calc(var(--s) * 5)',
    marginInlineStart: 'auto',
    transform: 'scale(1)',
    transformOrigin: 'right center',
    transition: 'all 0.5s ease-in-out',

    ':hover': { color: 'rgb(var(--fg))' },
    ':focus': {
      color: 'rgb(var(--fg))',
      outline: 'none',
    },
  },
  icon: {
    // display: 'inline-block',
    fontSize: 'calc(var(--s) * 5)',
    // lineHeight: 'calc(var(--s) * 5)',
    paddingInlineEnd: 'calc(var(--s) * 1)',
    opacity: 0,
    transform: 'translateY(-0.05em)',
    animationDelay: '0.25s',
    animationDuration: '0.5s',
    animationFillMode: 'forwards',
    animationIterationCount: 1,
    animationTimingFunction: 'ease-in-out',
    animationName: s9.keyframes({
      '0%': {
        opacity: 0,
      },
      '100%': {
        opacity: 1,
      },
    }),
  },
  error: {
    color: 'rgb(255,0,0)',
    cursor: 'default',
    animationDuration: '0.5s',
    animationFillMode: 'forwards',
    animationIterationCount: 1,
    animationTimingFunction: 'linear',
    animationName: s9.keyframes({
      '0%': {
        opacity: 1,
        transform: 'scale(1)',
      },
      '40%': {
        opacity: 0,
        transform: 'scale(0)',
      },
      '50%': {
        opacity: 0,
        transform: 'scale(0)',
      },
      '100%': {
        opacity: 1,
        transform: 'scale(1)',
      },
    }),

    ':hover': { color: 'rgb(255,0,0)' },
    ':focus': { color: 'rgb(255,0,0)' },
  },
  checkmark: {
    color: 'rgb(var(--accent2))',
    cursor: 'default',
    transitionDelay: '0.25s',
    animationDuration: '0.5s',
    animationFillMode: 'forwards',
    animationIterationCount: 1,
    animationTimingFunction: 'linear',
    animationName: s9.keyframes({
      '0%': {
        opacity: 1,
        transform: 'scale(1)',
      },
      '40%': {
        opacity: 0,
        transform: 'scale(0)',
      },
      '50%': {
        opacity: 0,
        transform: 'scale(0)',
      },
      '100%': {
        opacity: 1,
        transform: 'scale(1)',
      },
    }),

    ':hover': { color: 'rgb(var(--accent2))' },
    ':focus': { color: 'rgb(var(--accent2))' },
    ':active': { transform: 'scale(1)' },
  },
});

const INITIAL_TEXT = 'Copy to clipboard';

interface PreCopyButtonProps {
  /** The ID of the element to copy text from */
  copyFrom: string;
}
export default function PreCopyButton({ copyFrom }: PreCopyButtonProps) {
  const [icon, setIcon] = useState<Extract<IconName, 'error' | 'checkmark'> | false>(false);

  function failedCopy() {
    setIcon('error');
    setTimeout(() => setIcon(false), 5000);
  }

  function clickHandler() {
    const elementToCopy = document.getElementById(copyFrom);
    const textToCopy = elementToCopy?.textContent;

    if (textToCopy) {
      if (icon !== false) return;

      if (navigator.clipboard) {
        try {
          navigator.clipboard?.writeText(textToCopy).then(
            () => {
              /* clipboard successfully set */
              setIcon('checkmark');
              setTimeout(() => setIcon(false), 5000);
            },
            () => failedCopy()
          );
        } catch (_err) {
          failedCopy();
        }
      } else failedCopy();
    }
  }

  return (
    <button
      className={s9(c.preCopyButton, icon && c[icon])}
      onClick={clickHandler}
      aria-disabled={!!icon || undefined}
    >
      {icon ? (
        <>
          <span className={s9(c.icon)}>
            <Icon icon={icon} />
          </span>
          <Content state={icon} />
        </>
      ) : (
        INITIAL_TEXT
      )}
    </button>
  );
}

function Content({ state }: { state: 'checkmark' | 'error' }) {
  const [text, setText] = useState(INITIAL_TEXT);

  setTimeout(() => setText(state === 'checkmark' ? 'Copied to clipboard' : 'Failed to copy'), 400);

  return text;
}
