import s9 from 'style9';

const c = s9.create({
  base: {
    height: '1em',
    width: '1em',
  },
});

export type IconName = 'file-pdf' | 'file-word' | 'file-sheet' | 'link' | 'error' | 'checkmark';

interface IconProps extends React.SVGAttributes<SVGSVGElement> {
  icon: IconName;
  a11yLabel?: string;
  a11yDescription?: string;
  focusable?: boolean;
}

export default function Icon({
  a11yLabel,
  a11yDescription,
  focusable = false,
  icon,
  ...attrs
}: IconProps) {
  const hasA11yText = a11yLabel || a11yDescription;
  const descriptionId = `icdD${Math.random()}`;
  const a11yAttrs = hasA11yText
    ? {
        'aria-label': a11yLabel,
        ...(a11yDescription ? { 'aria-describedby': descriptionId } : {}),
      }
    : { 'aria-hidden': true, role: 'img' };
  const focusAttrs =
    focusable && hasA11yText ? { focusable: true } : { focusable: false, tabIndex: -1 };
  const desc = a11yDescription ? <desc id={descriptionId}>{a11yDescription}</desc> : null;

  if (focusable && !(a11yDescription || a11yLabel)) {
    console.error(
      `An "${icon}" icon is set to be focusable but has no a11y label or description\n`,
      'The "focusable" attribute will not be assigned'
    );
  }

  return (
    <svg {...focusAttrs} {...attrs} {...a11yAttrs} className={s9(c.base)}>
      {desc}
      <use xlinkHref={`#icn-${icon}`} />
    </svg>
  );
}
